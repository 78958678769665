'use client';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import useSubmitNewVote from '@haaretz/s-client-data-hooks/comments/useSubmitNewVote';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import React from 'react';
import s9 from 'style9';

import {
  useReportAbuseContext,
  useReportAbuseActionsContext,
} from './ReportAbuse/ReportAbuseContext';

import type { CommentFragment } from '@haaretz/s-fragments/Comment';

const c = s9.create({
  likeButtons: {
    display: 'flex',
    alignItems: 'center',
    columnGap: space(1),
    backgroundColor: 'transparent',
    color: color('neutral1100'),
    transitionDuration: '0.2s',
    transitionProperty: 'color',
    transitionTimingFunction: 'ease-in-out',

    ':hover': {
      color: color('neutral900'),
    },
  },
  disabled: {
    opacity: 0.35,
    pointerEvents: 'none',
  },
  icon: {
    fontSize: space(4),
  },
  likeButtonsText: {
    color: color('neutral900'),
    ...typesetter(-2),

    ...merge(mq({ from: 'xxl', value: { ...typesetter(-3) } })),
  },
  likeIcon: {
    marginBottom: space(1.5),
  },
});

type LikeDislikeBtnProps = {
  commentId: CommentFragment['commentId'];
} & (
  | {
      likes: CommentFragment['likes'];
      dislikes?: never;
    }
  | {
      likes?: never;
      dislikes: CommentFragment['dislikes'];
    }
);

function LikeDislikeBtn({ commentId, likes, dislikes }: LikeDislikeBtnProps) {
  const isLike = likes != null;
  const [votes, setVotes] = React.useState((isLike ? likes : dislikes) || 0);
  const biAction = useBi();
  const { hasAlreadyVoted } = useReportAbuseContext();
  const { setHasAlreadyVoted } = useReportAbuseActionsContext();

  const { mutate, isLoading } = useSubmitNewVote();

  const submitNewVoteHandler = async () => {
    setHasAlreadyVoted(true);
    setVotes(prev => prev + 1);
    mutate({ commentId, [isLike ? 'like' : 'dislike']: true });
  };

  return (
    <button
      className={s9(c.likeButtons, hasAlreadyVoted && c.disabled)}
      disabled={isLoading || hasAlreadyVoted}
      onClick={() => {
        submitNewVoteHandler();
        biAction({
          feature: 'Talkbacks',
          feature_type: 'Content',
          campaign_details: isLike ? 'thumbs up' : 'thumbs down',
          action_id: 186,
        });
      }}
      data-testid={`${isLike ? 'like' : 'dislike'}-btn`}
    >
      <Icon icon={isLike ? 'like' : 'dislike'} styleExtend={[c.icon, isLike && c.likeIcon]} />
      <span className={s9(c.likeButtonsText)}>{votes}</span>
    </button>
  );
}

export default function LikeDislikeBtnWrapper(props: LikeDislikeBtnProps) {
  return (
    <React.Suspense fallback={null}>
      <LikeDislikeBtn {...props} />
    </React.Suspense>
  );
}
