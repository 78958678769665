'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import useUser from '@haaretz/s-atoms/user';
import Button from '@haaretz/s-button';
import Chip from '@haaretz/s-chip';
import useGetUserNickname from '@haaretz/s-client-data-hooks/comments/useGetUserNickname';
import useSetUserNickname from '@haaretz/s-client-data-hooks/comments/useSetUserNickname';
import useSubmitNewComment from '@haaretz/s-client-data-hooks/comments/useSubmitNewComment';
import FormfieldDescription from '@haaretz/s-formfield-description';
import Icon from '@haaretz/s-icon';
import { TextFieldInput, TextFieldWrapper } from '@haaretz/s-text-field';
import useBi from '@haaretz/s-use-bi';
import useOnce from '@haaretz/s-use-once';
import * as React from 'react';
import s9 from 'style9';

import { useCommentsContext } from '../CommentsContextProvider';

import CommentField from './CommentField';
import ErrorDialog from './dialogs/ErrorDialog';
import PaywallDialog from './dialogs/PaywallDialog';
import SuccessDialog from './dialogs/SuccessDialog';

import type { PageType } from '@haaretz/s-types';
import type { JSX } from 'react';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',

    ...border({
      color: color('neutral600'),
      spacing: 1,
      style: 'solid',
      width: '1px',
      side: 'bottom',
    }),
  },
  nameFieldWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
  },
  nameDescWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nicknameBtn: {
    backgroundColor: 'transparent',
    color: color('primary1100'),
    textAlign: 'start',
    marginInlineStart: space(2),
    fontWeight: 700,
    maxWidth: space(18),
    ...typesetter(-2, { lines: 4 }),

    ...merge(
      // typesetter
      mq({ from: 'xl', until: 'xxl', value: { ...typesetter(-2, { lines: 5 }) } }),
      mq({ from: 'xxl', value: { ...typesetter(-3, { lines: 5 }) } }),

      // maxWidth
      mq({ from: 'xl', value: { maxWidth: space(21) } })
    ),

    ':hover': {
      color: color('primary900'),
    },
  },
  buttonsWrapper: {
    display: 'flex',
    marginTop: space(5),
    marginBottom: space(5),
    columnGap: space(2),
  },
  isInvalid: {
    color: color('secondary900'),
  },
  successIconWrapper: {
    width: space(6),
    marginInlineStart: space(2),

    ...mq({
      from: 'xl',
      value: {
        width: space(6.75),
      },
    }),
  },
  succesIcon: {
    fontSize: space(4),
    ...mq({
      from: 'xl',
      value: {
        fontSize: space(4.75),
      },
    }),
  },
  removeBorder: {
    borderBottomStyle: 'none',
  },
  hide: {
    display: 'none',
  },
  chipWrapper: {
    alignItems: 'baseline',
    columnGap: space(2),
    display: 'flex',
    fontWeight: 700,
  },
  removeNameChip: {
    paddingInlineEnd: space(1),
    width: 'max-content',

    ':hover': {
      backgroundColor: undefined,
    },
    ':active': {
      transform: 'scale(1)',
    },
  },
  removeNameBtn: {
    alignItems: 'center',
    aspectRatio: '1',
    backgroundColor: color('secondary900'),
    borderRadius: radius('circle'),
    color: color('neutral100'),
    display: 'flex',
    fontSize: space(4),
    height: space(6),
    justifyContent: 'center',
    marginInlineStart: space(2),
    textAlign: 'start',
    transitionDuration: '0.2s',
    transitionProperty: 'all',
    transitionTimingFunction: 'ease-in-out',
    marginInlineEnd: space(-2),

    ':focus': {
      backgroundColor: color('secondary1000'),
      outline: 'none',
    },
    ':hover': {
      backgroundColor: color('secondary1000'),
    },
    ':active': {
      transform: 'scale(0.95)',
    },
  },
  fadeOut: {
    opacity: 0,
  },
  transition: {
    transitionProperty: 'opacity',
    transitionDuration: '0.25s',
    transitionTimingFunction: 'ease-in-out',
  },
  commentsFormWrapper: {
    position: 'relative',
  },
  quoteVariant: {
    gridColumnStart: '1',
    gridColumnEnd: '3',
    gridRowStart: '2',
  },
});

const MAX_LENGTH_NAME = 20;

const genericError = fork({
  default: 'אירעה שגיאה, אנא נסו מאוחר יותר',
  hdc: 'Something went wrong, please try again later',
});

const texts = {
  editBtn: fork({ default: 'עדכנו', hdc: 'Update' }),
  setNicknameBtn: fork({ default: 'לבחירת השם ככינוי קבוע', hdc: 'Get a nickname' }),
  paywallAcceptBtn: fork({ default: 'כן גם אני רוצה', hdc: 'I want a nickname' }),
  successUpdateBtn: fork({ default: 'עדכנו אותי', hdc: 'Keep me posted' }),
};

export interface CommentFormProps extends React.RefAttributes<HTMLFormElement> {
  articleId: string;
  /**
   * The form shown when user clicks on reply button.
   */
  isReplyForm?: boolean;
  /**
   * Removes the reply form.
   */
  cancelReplyForm?: () => void;
  /**
   * The author (name or nickname) the user replies to.
   */
  replyTo?: string;
  /**
   * The parent comment id the user replies to.
   */
  parentCommentId?: string;
  pageType?: PageType;
}

export type DialogType = 'paywall' | 'success' | 'none' | 'error' | 'used';

function CommentForm({
  ref: forwardedRef,
  articleId,
  isReplyForm = false,
  cancelReplyForm,
  replyTo,
  parentCommentId,
  pageType,
}: CommentFormProps) {
  const [isValidNameField, setIsValidNameField] = React.useState(false);
  const [errorNameField, setErrorNameField] = React.useState<string | null>(null);
  const [errorCommentField, setErrorCommentField] = React.useState<string | null>(null);
  const commentFieldRef = React.useRef<HTMLDivElement | null>(null);
  const nameFieldRef = React.useRef<HTMLInputElement | null>(null);
  const nameFieldId = React.useId();
  const userNicknameMutation = useSetUserNickname();
  const user = useUser('cookieValue');
  const [dialogType, setDialogType] = React.useState<DialogType>('none');
  const [commentId, setCommentId] = React.useState<string | null>(null);
  const submitNewCommentMutation = useSubmitNewComment();
  const [userNickname, setUserNickname] = React.useState<string | null>(null);
  const [isSetNicknameLoading, setIsSetNicknameLoading] = React.useState(false);
  const biAction = useBi();

  const [startNameFieldFadeOut, setStartNameFieldFadeOut] = React.useState(false);
  const [startRemoveNameFadeOut, setStartRemoveNameFadeOut] = React.useState(false);
  const { isInView } = useCommentsContext();

  const { status, data } = useGetUserNickname({
    enabled: typeof window !== 'undefined' && isInView && user.userType === 'paying',
  });

  const nickname = data?.nickname;

  useOnce(
    () => {
      nickname && setUserNickname(nickname);
      setIsValidNameField(true);
    },
    status === 'success' && !!nickname
  );

  const nameFieldHandler = (ref: React.MutableRefObject<HTMLInputElement | null>) => {
    setIsValidNameField(true);
    setErrorNameField(null);

    const value = ref?.current?.value || userNickname;

    if (!value) {
      setErrorNameField(fork({ default: 'חובה להזין שם', hdc: 'Name is required' }));
      setIsValidNameField(false);
    } else if (value.length > MAX_LENGTH_NAME) {
      setErrorNameField(
        fork({
          default: `אין להזין יותר מ-${MAX_LENGTH_NAME} תווים`,
          hdc: `Name max length is ${MAX_LENGTH_NAME} chars`,
        })
      );
      setIsValidNameField(false);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (dialogType === 'none') {
      nameFieldHandler(nameFieldRef);

      const nameFieldValue = nameFieldRef?.current?.value || userNickname;
      const commentFieldValue = commentFieldRef?.current?.innerHTML;

      if (!commentFieldValue) {
        setErrorCommentField(
          fork({ default: 'נא להזין את תוכן התגובה', hdc: 'Comment is required' })
        );
      }

      if (isValidNameField && nameFieldValue && !errorCommentField && commentFieldValue) {
        const name = nameFieldValue.trim();
        const comment = commentFieldValue.trim();

        biAction({
          feature: 'Talkbacks',
          feature_type: 'Content',
          campaign_name: 'add comment',
          action_id: 1,
        });

        submitNewCommentMutation.mutate(
          {
            articleId,
            authorName: name,
            text: comment,
            parentCommentId,
            replyTo,
          },
          {
            onSuccess: ({ status: commentStatus, errorCode, commentId: newCommentId }) => {
              if (commentStatus === 'ok' && newCommentId) {
                setDialogType('success');
                setCommentId(newCommentId);
              } else {
                if (errorCode === '303') setDialogType('used');
                else setDialogType('error');
              }
            },
            onError: () => {
              setDialogType('error');
            },
          }
        );
      }
    }
  };

  const setNicknameHandler = (ref: React.MutableRefObject<HTMLInputElement | null>) => {
    nameFieldHandler(ref);
    if (user.userType === 'paying') {
      const value = ref?.current?.value;
      if (isValidNameField && value) {
        setIsSetNicknameLoading(true);
        userNicknameMutation.mutate(
          { nickname: value },
          {
            onSuccess: async ({ status: nicknameStatus }) => {
              if (nicknameStatus === 'ok') {
                setDialogType('none');
                setStartNameFieldFadeOut(true);
              } else if (nicknameStatus === 'exist') {
                setErrorNameField(
                  fork({
                    default: 'הכינוי שבחרת תפוס. יש לבחור כינוי אחר',
                    hdc: 'The nickname is already in use',
                  })
                );
              } else {
                setErrorNameField(genericError);
              }
            },
            onError: () => {
              setErrorNameField(genericError);
            },
            onSettled: () => {
              setIsSetNicknameLoading(false);
            },
          }
        );
      }
    } else {
      setDialogType('paywall');
    }
  };

  const removeNicknameHandler = () => {
    biAction({
      feature: 'Talkbacks',
      feature_type: 'Content',
      campaign_name: 'remove nickname',
      campaign_details: 'ביטול כינוי',
      action_id: 169,
    });

    userNicknameMutation.mutate(
      { nickname: '' },
      {
        onSuccess: async ({ status: nicknameStatus }) => {
          if (nicknameStatus === 'ok') {
            setDialogType('none');
            setStartRemoveNameFadeOut(true);
          } else {
            setDialogType('error');
          }
        },
        onError: () => {
          setDialogType('error');
        },
      }
    );
  };

  const sharedWrapperProps = {
    setErrorNameField,
    setDialogType,
    dialogType,
  };

  const componentByDialogType: Omit<Record<DialogType, JSX.Element>, 'none'> = {
    paywall: <PaywallDialog sharedWrapperProps={sharedWrapperProps} />,
    success: (
      <SuccessDialog
        commentId={commentId}
        setCommentId={setCommentId}
        userMail={user.userMail || ''}
        cancelReplyForm={cancelReplyForm}
        isReplyForm={isReplyForm}
        sharedWrapperProps={sharedWrapperProps}
      />
    ),
    error: <ErrorDialog sharedWrapperProps={sharedWrapperProps} />,
    used: <ErrorDialog sharedWrapperProps={sharedWrapperProps} type="used" />,
  };

  return (
    <div
      className={s9(
        c.commentsFormWrapper,
        (pageType === 'QuotesArticle' || pageType === 'MidrugArticle') && c.quoteVariant
      )}
    >
      <form
        className={s9(c.base, dialogType !== 'none' && c.hide, isReplyForm && c.removeBorder)}
        onSubmit={onSubmit}
        ref={forwardedRef}
        data-testid="comment-form"
      >
        {userNickname ? (
          <span
            className={s9(
              c.chipWrapper,
              c.removeNameChip,
              c.transition,
              startRemoveNameFadeOut && c.fadeOut
            )}
            onTransitionEnd={(e: React.TransitionEvent<HTMLSpanElement>) => {
              if (e.propertyName === 'opacity' && startRemoveNameFadeOut) {
                setStartRemoveNameFadeOut(false);
                setUserNickname(null);
              }
            }}
          >
            {fork({
              default: 'כינוי: ',
              hdc: 'Nickname: ',
            })}
            <Chip as="span" data-testid="remove-name-chip">
              {userNickname}
              <button
                className={s9(c.removeNameBtn)}
                type="button"
                onClick={() => removeNicknameHandler()}
                data-testid="remove-nickname-btn"
              >
                <Icon icon="close" />
              </button>
            </Chip>
          </span>
        ) : (
          <TextFieldWrapper
            isInvalid={!!errorNameField}
            styleExtend={[c.nameFieldWrapper, c.transition, startNameFieldFadeOut && c.fadeOut]}
            onTransitionEnd={e => {
              // We check the value is valid in the setNicknameHandler onSuccess,
              // which is the only place triggering this
              if (e.propertyName === 'opacity' && nameFieldRef?.current?.value) {
                setStartNameFieldFadeOut(false);
                setUserNickname(nameFieldRef?.current?.value);
              }
            }}
          >
            <TextFieldInput
              label="שם"
              name="name"
              type="text"
              isInvalid={!!errorNameField}
              onChange={() => nameFieldHandler(nameFieldRef)}
              maxLength={MAX_LENGTH_NAME}
              onBlur={() => nameFieldHandler(nameFieldRef)}
              aria-describedby={nameFieldId}
              ref={nameFieldRef}
              state={userNickname || submitNewCommentMutation.isLoading ? 'disabled' : 'default'}
              defaultValue={userNickname || ''}
              key={userNickname}
              required
              data-testid="name-input"
            />
            <button
              className={s9(c.nicknameBtn)}
              type="button"
              onClick={() => {
                setNicknameHandler(nameFieldRef);
                biAction({
                  feature: 'Talkbacks',
                  feature_type: 'Content',
                  campaign_name: 'Nickname link',
                  campaign_details: texts.setNicknameBtn,
                  action_id: 169,
                });
              }}
              disabled={isSetNicknameLoading}
              aria-disabled={isSetNicknameLoading ? 'true' : undefined}
              data-testid="set-nickname-btn"
            >
              {texts.setNicknameBtn}
            </button>
            <div className={s9(c.nameDescWrapper)}>
              <FormfieldDescription isInvalid={!!errorNameField} id={nameFieldId}>
                {errorNameField ||
                  fork({ default: 'הזינו שם שיוצג באתר', hdc: 'Enter the commenter display name' })}
              </FormfieldDescription>
            </div>
          </TextFieldWrapper>
        )}
        <CommentField
          ref={commentFieldRef}
          errorCommentField={errorCommentField}
          setErrorCommentField={setErrorCommentField}
          dialogType={dialogType}
          isLoading={submitNewCommentMutation.isLoading}
        />
        <div className={s9(c.buttonsWrapper)}>
          <Button
            type="submit"
            data-testid="submit-btn"
            {...(submitNewCommentMutation.isLoading
              ? {
                  state: 'busy',
                  busyNotice: fork({ default: 'טוען...', hdc: 'loading...' }),
                }
              : { state: 'auto' })}
          >
            שלחו
          </Button>
          {isReplyForm && (
            <Button
              variant="danger"
              priority="tertiary"
              type="button"
              onClick={cancelReplyForm}
              data-testid="cancel-reply-form-btn"
            >
              בטלו
            </Button>
          )}
        </div>
      </form>
      {dialogType !== 'none' && componentByDialogType[dialogType]}
    </div>
  );
}

export default function SuspenseWrapper({ ref: forwardedRef, ...props }: CommentFormProps) {
  return (
    <React.Suspense fallback={null}>
      <CommentForm {...props} ref={forwardedRef} />
    </React.Suspense>
  );
}
