import useClientMutation from '../../common/useClientMutation';
import { COMMENTS_URL } from '../../consts';

import type {
  SelectEditorsPickData,
  SelectEditorsPickVariables,
} from '@haaretz/s-data-structure-types';

export default function useSelectEditorsPick() {
  const searchParams = new URLSearchParams({
    action: 'editorsPick',
  });

  return useClientMutation<SelectEditorsPickData, SelectEditorsPickVariables>({
    cacheKey: 'selectEditorsPick',
    url: COMMENTS_URL,
    clientOptions: { cacheTime: 0, retry: false },
    searchParams,
    fetchOptions: { method: 'GET' },
  });
}
