'use client';

import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import Button from '@haaretz/s-button';
import useReportAbuse from '@haaretz/s-client-data-hooks/comments/useReportAbuse';
import React from 'react';
import s9 from 'style9';

import { useReportAbuseContext, useReportAbuseActionsContext } from './ReportAbuseContext';

import type { CommentFragment } from '@haaretz/s-fragments/Comment';

const c = s9.create({
  reportAbuseBtn: {
    paddingInlineStart: 0,
    paddingInlineEnd: 0,

    ...mq({
      until: 'xl',
      value: {
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
      },
    }),
  },
  disablePointerEvents: {
    pointerEvents: 'none',
  },
});

type ReportAbuseBtnProps = {
  commentId: CommentFragment['commentId'];
  commentsElementId: string;
};

export default function ReportAbuseBtn({ commentId, commentsElementId }: ReportAbuseBtnProps) {
  const { reportAbuseStatus, captchaKey, btnState } = useReportAbuseContext();
  const { setReportAbuseStatus, setShouldInitCaptcha, setBtnState } =
    useReportAbuseActionsContext();

  const alreadyExecutedRef = React.useRef(false);
  const useReportAbuseMutation = useReportAbuse();
  const isReportAbuseStatusSuccess = reportAbuseStatus === 'success';

  const initReportAbuse = React.useCallback(
    (token: string | null) => {
      if (token) {
        useReportAbuseMutation.mutate(
          {
            'g-recaptcha-response': token,
            commentId,
            commentElementId: commentsElementId,
          },
          {
            onSuccess: ({ status }) => {
              if (status === 'ok') {
                setReportAbuseStatus('success');
              } else {
                setReportAbuseStatus('error');
              }
            },
            onError: () => {
              setReportAbuseStatus('error');
            },
            onSettled: () => {
              setBtnState('auto');
            },
          }
        );
      } else {
        setReportAbuseStatus('error');
        setBtnState('auto');
      }
    },
    [commentId, commentsElementId, setBtnState, setReportAbuseStatus, useReportAbuseMutation]
  );

  React.useEffect(() => {
    if (captchaKey && !alreadyExecutedRef.current) {
      initReportAbuse(captchaKey);
      alreadyExecutedRef.current = true;
    }
  }, [captchaKey, initReportAbuse]);

  const reportAbuseHandler = () => {
    if (isReportAbuseStatusSuccess) {
      return;
    }

    setBtnState('busy');
    if (captchaKey) {
      initReportAbuse(captchaKey);
    } else {
      setShouldInitCaptcha(true);
    }
  };

  return (
    <Button
      variant="danger"
      priority="tertiary"
      styleExtend={[c.reportAbuseBtn, isReportAbuseStatusSuccess && c.disablePointerEvents]}
      size="small"
      onClick={reportAbuseHandler}
      data-testid="report-abuse-btn"
      {...(btnState === 'busy'
        ? {
            state: btnState,
            busyNotice: fork({ default: 'טוען...', hdc: 'loading...' }),
          }
        : { state: btnState })}
    >
      {isReportAbuseStatusSuccess
        ? fork({ default: 'הדיווח התקבל', hdc: 'Report received' })
        : reportAbuseStatus === 'error'
          ? fork({ default: 'אירעה שגיאה, נסו שוב', hdc: 'Try again' })
          : fork({ default: 'דווחו כפוגעני', hdc: 'Report' })}
    </Button>
  );
}
