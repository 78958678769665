import useClientMutation from '../../common/useClientMutation';
import { COMMENTS_URL } from '../../consts';

import type { RejectCommentData, RejectCommentVariables } from '@haaretz/s-data-structure-types';

export default function useCommentsCountQuery() {
  const searchParams = new URLSearchParams({
    action: 'reject',
  });

  return useClientMutation<RejectCommentData, RejectCommentVariables>({
    cacheKey: 'rejectComment',
    url: COMMENTS_URL,
    clientOptions: { cacheTime: 0, retry: false },
    searchParams,
    fetchOptions: { method: 'GET' },
  });
}
