'use client';

import React from 'react';

import type { ButtonState } from '@haaretz/s-button';

type ReportAbuseContextType = {
  reportAbuseStatus?: 'success' | 'error';
  captchaKey: string | null;
  shouldInitCaptcha: boolean;
  btnState: ButtonState['state'];
  hasAlreadyVoted: boolean;
};

type ReportAbuseActionsContextType = {
  setReportAbuseStatus: React.Dispatch<React.SetStateAction<'success' | 'error' | undefined>>;
  setCaptchaKey: React.Dispatch<React.SetStateAction<string | null>>;
  setShouldInitCaptcha: React.Dispatch<React.SetStateAction<boolean>>;
  setBtnState: React.Dispatch<React.SetStateAction<ButtonState['state']>>;
  setHasAlreadyVoted: React.Dispatch<React.SetStateAction<boolean>>;
};

const ReportAbuseContext = React.createContext<ReportAbuseContextType | undefined>(undefined);
const ReportAbuseActionsContext = React.createContext<ReportAbuseActionsContextType | undefined>(
  undefined
);

export function ContextProvider({ children }: React.PropsWithChildren) {
  const [reportAbuseStatus, setReportAbuseStatus] = React.useState<
    undefined | 'success' | 'error'
  >();
  const [captchaKey, setCaptchaKey] = React.useState<null | string>(null);
  const [shouldInitCaptcha, setShouldInitCaptcha] = React.useState(false);
  const [btnState, setBtnState] = React.useState<ButtonState['state']>('auto');
  const [hasAlreadyVoted, setHasAlreadyVoted] = React.useState(false);

  const state = React.useMemo(
    () => ({
      reportAbuseStatus,
      captchaKey,
      shouldInitCaptcha,
      btnState,
      hasAlreadyVoted,
    }),
    [btnState, captchaKey, hasAlreadyVoted, reportAbuseStatus, shouldInitCaptcha]
  );

  const actions = React.useMemo(
    () => ({
      setReportAbuseStatus,
      setCaptchaKey,
      setShouldInitCaptcha,
      setBtnState,
      setHasAlreadyVoted,
    }),
    []
  );

  return (
    <ReportAbuseContext value={state}>
      <ReportAbuseActionsContext value={actions}>{children}</ReportAbuseActionsContext>
    </ReportAbuseContext>
  );
}

export function useReportAbuseContext() {
  const context = React.use(ReportAbuseContext);

  if (context === undefined) {
    throw new Error('useReportAbuseContext must be used within a ReportAbuseContext.');
  }

  return context;
}

export function useReportAbuseActionsContext() {
  const context = React.use(ReportAbuseActionsContext);

  if (context === undefined) {
    throw new Error(
      'useReportAbuseActionsContext must be used within a ReportAbuseActionsContext.'
    );
  }

  return context;
}

export default function ReportAbuseContextProvider({ children }: React.PropsWithChildren) {
  return <ContextProvider>{children}</ContextProvider>;
}
