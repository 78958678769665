import useClientMutation from '../../common/useClientMutation';
import { COMMENTS_URL } from '../../consts';

import type {
  SubmitCommentNotificationEmailData,
  SubmitCommentNotificationEmailVariables,
} from '@haaretz/s-data-structure-types';

export default function useSubmitCommentNotificationEmail() {
  const searchParams = new URLSearchParams({
    action: 'setAuthorEmail',
    ts: Date.now().toString(),
  });

  return useClientMutation<
    SubmitCommentNotificationEmailData,
    SubmitCommentNotificationEmailVariables
  >({
    cacheKey: 'submitCommentNotificationEmail',
    url: COMMENTS_URL,
    clientOptions: { cacheTime: 0, retry: false },
    searchParams,
    fetchOptions: { method: 'GET' },
  });
}
