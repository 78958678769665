'use client';

import fork from '@haaretz/l-fork.macro';
import Button from '@haaretz/s-button';
import useRejectComment from '@haaretz/s-client-data-hooks/comments/useRejectComment';
import useSelectEditorsPick from '@haaretz/s-client-data-hooks/comments/useSelectEditorsPick';
import React from 'react';
import s9 from 'style9';

import type { ButtonProps } from '@haaretz/s-button';
import type { CommentFragment } from '@haaretz/s-fragments/Comment';

const c = s9.create({
  btn: {
    paddingInlineStart: 0,
    paddingInlineEnd: 0,
  },
});

type CommentsModActionsProps = {
  commentId: CommentFragment['commentId'];
};

const EditorBtn = ({ children, ...props }: React.PropsWithChildren<ButtonProps>) => {
  return (
    <Button priority="tertiary" styleExtend={[c.btn]} {...props}>
      {children}
    </Button>
  );
};

const textByStatus = {
  success: 'בוצע',
  error: 'משהו השתבש. נסו שנית',
};

export default function CommentsModActions({ commentId }: CommentsModActionsProps) {
  const rejectCommentMutation = useRejectComment();
  const selectEditorsPickMutation = useSelectEditorsPick();
  const [selectedCommentId, setSelectedCommentId] = React.useState<string | null>(null);
  const [status, setStatus] = React.useState<'success' | 'error' | null>(null);

  const rejectOnClick = () => {
    setStatus(null);

    rejectCommentMutation.mutate(
      {
        commentId,
      },
      {
        onSuccess: ({ status: rejectStatus }) => {
          if (rejectStatus === 'ok') {
            setStatus('success');
          } else {
            setStatus('error');
          }
        },
        onError: () => {
          setStatus('error');
        },
        onSettled: () => setSelectedCommentId(commentId),
      }
    );
  };

  const editorPickOnClick = () => {
    setStatus(null);

    selectEditorsPickMutation.mutate(
      {
        commentId,
      },
      {
        onSuccess: ({ status: editorPickStatus }) => {
          if (editorPickStatus === 'ok') {
            setStatus('success');
          } else {
            setStatus('error');
          }
        },
        onError: () => {
          setStatus('error');
        },
        onSettled: () => setSelectedCommentId(commentId),
      }
    );
  };
  return (
    <>
      <EditorBtn
        onClick={rejectOnClick}
        onBlur={() => setStatus(null)}
        variant="danger"
        {...(rejectCommentMutation.isLoading
          ? { state: 'busy', busyNotice: 'טוען...' }
          : { state: 'auto' })}
      >
        {fork({ default: 'דחייה', hdc: 'Reject' })}
      </EditorBtn>
      <EditorBtn
        onClick={editorPickOnClick}
        onBlur={() => setStatus(null)}
        variant="success"
        {...(selectEditorsPickMutation.isLoading
          ? { state: 'busy', busyNotice: 'טוען...' }
          : { state: 'auto' })}
      >
        {fork({ default: 'בחירת העורכים', hdc: "Editor's pick" })}
      </EditorBtn>
      {commentId === selectedCommentId && status ? textByStatus[status] : null}
    </>
  );
}
