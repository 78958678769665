import useClientQuery from '../../common/useClientQuery';
import { SITE_USER_URL } from '../../consts';

import type { GetUserNicknameData } from '@haaretz/s-data-structure-types';

const CACHE_TIME = 1000 * 10; // 10 seconds

const searchParams = new URLSearchParams({
  action: 'getNickname',
});

export default function useGetUserNickname({ enabled }: { enabled?: boolean }) {
  return useClientQuery<GetUserNicknameData>({
    cacheKey: 'getUserNickname',
    url: SITE_USER_URL,
    searchParams,
    clientOptions: {
      useErrorBoundary: false,
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME,
      retry: false,
      enabled,
    },
  });
}
