import useClientMutation from '../../common/useClientMutation';
import { COMMENTS_URL } from '../../consts';

import type { SubmitNewVoteData, SubmitNewVoteVariables } from '@haaretz/s-data-structure-types';

export default function useSubmitNewVote() {
  const searchParams = new URLSearchParams({
    action: 'likeDislikeComment',
    ts: Date.now().toString(),
  });

  return useClientMutation<SubmitNewVoteData, SubmitNewVoteVariables>({
    cacheKey: 'submitNewVote',
    url: COMMENTS_URL,
    clientOptions: { cacheTime: 0, retry: false },
    searchParams,
    fetchOptions: { method: 'GET' },
  });
}
