import React from 'react';

interface UseIntersectionObserverOpts extends IntersectionObserverInit {
  elements?: React.RefObject<Element | null> | React.RefObject<Element | null>[];
  cb: IntersectionObserverCallback;
}

export default function useIntersectionObserver({
  elements,
  cb,
  threshold,
  rootMargin,
  root,
}: UseIntersectionObserverOpts) {
  const [observer, setObserver] = React.useState<IntersectionObserver>();
  React.useEffect(() => {
    if (!observer) {
      setObserver(new IntersectionObserver(cb, { threshold, rootMargin, root }));
    } else {
      if (Array.isArray(elements)) {
        for (const element of elements) {
          if (element.current) observer.observe(element.current);
        }
      } else if (elements?.current) observer.observe(elements.current);
    }

    return () => observer?.disconnect();
  }, [cb, elements, observer, root, rootMargin, threshold]);

  return observer;
}
