'use client';

import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import { useSearchParams } from 'next/navigation';
import React from 'react';

type CommentsContextType = {
  replyToCommentId?: string;
  replyToAuthor?: string;
  isInView?: boolean;
  isFromEmailNotification?: boolean;
  commentIdParam: string | null;
  subcommentIdParam: string | null;
};

type CommentsActionsContextType = {
  setReplyToCommentId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setReplyToAuthor: React.Dispatch<React.SetStateAction<string | undefined>>;
  cancelReplyForm: () => void;
  setIsInView: React.Dispatch<React.SetStateAction<boolean>>;
};

const CommentsContext = React.createContext<CommentsContextType | undefined>(undefined);
const CommentsActionsContext = React.createContext<CommentsActionsContextType | undefined>(
  undefined
);

export function ContextProvider({ children }: React.PropsWithChildren) {
  const [replyToCommentId, setReplyToCommentId] = React.useState<string | undefined>();
  const [replyToAuthor, setReplyToAuthor] = React.useState<string | undefined>();
  const searchParams = useSearchParams();
  const commentIdParam = searchParams.get('commentId');
  const subcommentIdParam = searchParams.get('subcommentId');
  const isFromEmailNotification = !!commentIdParam && !!subcommentIdParam;
  const [isInView, setIsInView] = React.useState(isFromEmailNotification);

  const cancelReplyForm = React.useCallback(() => {
    setReplyToCommentId('');
  }, []);

  const value = React.useMemo(
    () => ({
      replyToCommentId,
      replyToAuthor,
      isInView,
      isFromEmailNotification,
      commentIdParam,
      subcommentIdParam,
    }),
    [
      replyToCommentId,
      replyToAuthor,
      isInView,
      isFromEmailNotification,
      commentIdParam,
      subcommentIdParam,
    ]
  );

  const actions = React.useMemo(
    () => ({
      setReplyToCommentId,
      setReplyToAuthor,
      cancelReplyForm,
      setIsInView,
    }),
    [cancelReplyForm]
  );

  return (
    <CommentsContext value={value}>
      <CommentsActionsContext value={actions}>{children}</CommentsActionsContext>
    </CommentsContext>
  );
}

export function useCommentsContext() {
  const context = React.use(CommentsContext);

  if (context === undefined) {
    throw new Error('useCommentsContext must be used within a CommentsContext.');
  }

  return context;
}

export function useCommentsActionsContext() {
  const context = React.use(CommentsActionsContext);

  if (context === undefined) {
    throw new Error('useCommentsActionsContext must be used within a CommentsActionsContext.');
  }

  return context;
}

export default function CommentsContextProvider({ children }: React.PropsWithChildren) {
  const isLoaded = useLoadEvent();

  if (!isLoaded) return null;

  return <ContextProvider>{children}</ContextProvider>;
}
