import useClientMutation from '../../common/useClientMutation';
import { COMMENTS_URL } from '../../consts';

import type {
  SubmitNewCommentPersonalData,
  SubmitNewCommentVariables,
} from '@haaretz/s-data-structure-types';

export default function useSubmitNewComment() {
  const searchParams = new URLSearchParams({
    action: 'createComment',
    ts: Date.now().toString(),
  });

  return useClientMutation<SubmitNewCommentPersonalData, SubmitNewCommentVariables>({
    cacheKey: 'submitNewVote',
    url: COMMENTS_URL,
    searchParams,
    clientOptions: { cacheTime: 0, retry: false },
  });
}
